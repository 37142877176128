<template>
  <div class="main">
    <KTCodePreview :title="title">
      <!-- heading action -->
      <template v-slot:toolbar>
        <b-button
          variant="primary"
          size="sm"
          class="font-weight-bolder"
          @click="create()"
        >
          <i
            style="font-size: 1rem"
            class="flaticon2-add-1"
          ></i>Thêm mới
        </b-button>
      </template>
      <template v-slot:preview>
        <!-- searching -->
        <b-row class="mb-5">
          <b-col md="3">
            <b-input
              placeholder="Tìm kiếm"
              v-model="apiParams.searchName"
              size="sm"
            ></b-input>
          </b-col>
          <b-col md="3">
            <b-button
              size="sm"
              class="font-weight-bolder"
              variant="primary"
              @click="search()"
            >Lọc</b-button>
          </b-col>
          <b-col md="3">
          </b-col>
          <b-col md="3">
          </b-col>
        </b-row>
        <!-- list priority cart -->
        <div class="list-priority-cart">
          <div class="cart-table">
            <b-table
              :items="tableItems"
              :fields="tableFields"
              bordered
              hover
            >
              <template v-slot:table-busy>
                <vcl-table
                  :speed="5"
                  :animate="true"
                  :columns="6"
                ></vcl-table>
              </template>

              <template v-slot:cell(actions)="row">
                <div class="d-flex justify-content-center">
                  <b-dropdown
                    size="sm"
                    id="dropdown-left"
                    no-caret
                    right
                  >
                    <template slot="button-content">
                      <i
                        style="font-size: 1rem; padding-right: 0px"
                        class="flaticon2-settings"
                      ></i>
                    </template>
                    <b-dropdown-item @click="editItem(row.item)">
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem"
                          class="flaticon2-pen"
                        ></i>
                        &nbsp; Chỉnh sửa
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="showDeleteAlert(row.item)">
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem; color: #d33"
                          class="flaticon2-rubbish-bin-delete-button"
                        ></i>
                        &nbsp; Xóa
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
          </div>
          <div class="paginate">
            <b-row>
              <b-col>
                <p
                  class="mt-3 text-dark"
                  style="font-weight: 500"
                >
                  Tổng số giỏ hàng:
                  {{ convertPrice(totalItems) }}
                </p>
              </b-col>
              <b-col>
                <BPaginationNavCustom
                  :number-of-pages="totalPages"
                  :current-page="page"
                  :total-page="totalPages"
                  @page-change="fetchCarts"
                >
                </BPaginationNavCustom>
              </b-col>
            </b-row>
          </div>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { makeToastSuccess, makeToastFaile, convertPrice } from '@/utils/common';
import { formatDate } from '@/utils/date';
import debounce from 'debounce';
import { TIME_TRIGGER } from '@/utils/constants';
import BPaginationNavCustom from '@/view/base/bootstrap/BPaginationNavCustom.vue';

export default {
  components: {
    KTCodePreview,
    BPaginationNavCustom,
  },
  data() {
    return {
      modalTitle: 'Xóa giỏ hàng!',
      modalDesc: 'Bạn có chắc muốn xóa giỏ hàng này không ?',
      page: 1,
      pageSize: 10,
      totalPages: 0,
      totalItems: 0,
      currentPage: 0,
      apiParams: {
        searchName: '',
      },
      title: 'Giỏ hàng ưu tiên',
      tableItems: [],
      tableFields: [
        {
          key: 'id',
          label: 'ID',
          sortable: false,
        },
        {
          key: 'name',
          label: 'Tên giỏ hàng',
          sortable: false,
        },
        {
          key: 'startDate',
          label: 'Ngày bắt đầu',
          sortable: false,
          formatter: (value) => {
            return value ? formatDate(value, 'DD/MM/YYYY HH:mm:ss') : null;
          },
        },
        {
          key: 'endDate',
          label: 'Ngày kết thúc',
          sortable: false,
          formatter: (value) => {
            return value ? formatDate(value, 'DD/MM/YYYY HH:mm:ss') : null;
          },
        },
        {
          key: 'statusName',
          label: 'Trạng thái',
          sortable: false,
        },
        {
          key: 'updatedByName',
          label: 'Người chỉnh sửa',
          sortable: false,
        },
        {
          key: 'updatedAt',
          label: 'Thời gian chỉnh sửa',
          sortable: false,
          formatter: (value) => {
            return value ? formatDate(value, 'DD/MM/YYYY HH:mm:ss') : null;
          },
        },
        { key: 'actions', label: '' },
      ],
    };
  },
  created() {
    this.fetchCarts();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.title, route: '/priority-cart' },
      { title: 'Danh sách giỏ hàng ưu tiên' },
    ]);
  },
  methods: {
    convertPrice,
    deleteItem(cart) {
      ApiService.delete(`priority-cart/${cart.id}`)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            makeToastSuccess(message);
            this.fetchCarts();
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile(err.$error);
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
        });
    },
    showDeleteAlert(item) {
      this.deleteItem(item);
    },
    editItem(cart) {
      this.$router.push({
        name: 'upsert-priority-cart',
        query: { id: cart.id },
      });
    },
    fetchCarts() {
      this.onLoading = true;
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }

      const param = {
        page: this.page,
        pageSize: this.pageSize,
        name: this.apiParams.searchName.trim(),
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query('priority-cart', paramQuery).then((response) => {
        const dataResponse = response.data.data;
        this.tableItems = [];
        this.totalPages = dataResponse.totalPages || 1;
        this.totalItems = dataResponse.totalItems;
        this.currentPage = dataResponse.currentPage;
        this.tableItems = dataResponse.result;
      });
    },
    debounceSearch: debounce(function () {
      this.fetchCarts();
    }, TIME_TRIGGER),
    search() {
      this.debounceSearch();
    },
    create() {
      this.$router.push({
        name: 'upsert-priority-cart',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main ::v-deep {
  .card-toolbar {
    padding-right: 0;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>